<template>
  <div class="box">
    <languages v-if="!inputPassword"></languages>
    <img class="logo" src="../../assets/icon/chatgpt.jpg" />
    <h1 class="welcome">{{ $t("绑定邮箱") }}</h1>
    <div class="input-box">
      <!-- 邮箱 -->
      <label
        v-show="emailActive"
        :class="{ active: !emailActive }"
        :style="emailMsg ? 'color:#d00e17' : ''"
        >{{ $t("邮箱地址") }}</label
      >
      <input
        name="username"
        @focus="emailActive = true"
        @blur="emailBlur()"
        @keyup.enter="next()"
        v-model="userEmail"
        :placeholder="emailActive ? '' : $t('邮箱地址')"
        :style="emailMsg ? 'border: 1px solid #d00e17;' : ''"
      />
      <span class="error" v-if="emailRet === 5"
        ><i class="iconfont icon-error"></i>{{ $t("电话号码不能为空") }}</span
      >
      <span class="error" v-if="emailRet === 5.5"
        ><i class="iconfont icon-error"></i>{{ $t("邮箱不能为空") }}</span
      >
      <span class="error" v-if="emailRet === 4"
        ><i class="iconfont icon-error"></i>{{ $t("用户名格式无效") }}</span
      >
      <span class="error" v-if="emailRet === 3"
        ><i class="iconfont icon-error"></i>{{ $t("填写格式有误") }}</span
      >
      <span class="error" v-if="emailRet === 2"
        ><i class="iconfont icon-error"></i>{{ $t("用户已经存在") }}</span
      >
      <span class="error" v-if="emailRet === 1"
        ><i class="iconfont icon-error"></i
        >{{ $t("验证码已发送请勿重复获取") }}</span
      >
      <span class="error" v-if="emailRet === -1"
        ><i class="iconfont icon-error"></i>{{ $t("短信发送失败") }}</span
      >
    </div>

    <template v-if="inputPassword">
      <div class="input-box">
        <label
          class="verify-label"
          v-show="validateActive"
          :class="{ active: !validateActive }"
          >{{ $t("验证码") }}</label
        >
        <input
          name="validate"
          @focus="validateActive = true"
          @blur="validateBlur()"
          v-model="validateCode"
          :placeholder="validateActive ? '' : this.$t('验证码')"
          :maxlength="sendTest === $t('发送至邮箱') ? 4 : 6"
        />
        <span class="error" v-if="validateMsg != ''"
          ><i class="iconfont icon-error"></i>{{ validateMsg }}</span
        >
        <!-- 发送验证码 -->
        <button @click="sendEmail()" v-if="send_seconds == 0" class="send">
          {{ sendTest }}
        </button>
        <span class="email-timer" v-else>{{ sendEmailTip }}</span>
      </div>
      <!-- <div class="input-box">
          <label class="pass-label" v-show="codeActive" :class="{active:!codeActive}">Invite Code</label>
          <input name="invite" @focus="codeActive = true" @blur="codeBlur()" v-model="inviteCode"
            :placeholder="codeActive?'':'Invite Code'" />
          <span class="error" v-if="codeMsg != ''"><i class="iconfont icon-error"></i>{{codeMsg}}</span>
        </div> -->
    </template>

    <button class="continue" @click="next()">{{ $t("确定") }}</button>
    <!-- <span class="log-in">{{ $t('已经有账户了') }} ? <span @click="toLogin()">{{ $t('登录') }}</span> </span> -->
  </div>
</template>
<script>
import {
  checkUser,
  sendEmail,
  sendPhone,
  bindingEmail
} from "../../config/api.js";
import languages from "../../components/languages/index.vue";
import regexNumber from "../../utils/regexNumber";
export default {
  name: "bindEmail",
  components: { languages },
  data() {
    return {
      emailActive: false,
      passActive: false,
      confirmActive: false,
      codeActive: false,
      validateActive: false,
      emailMsg: "",
      emailRet: null,
      emailPlaceholder: null,
      passMsg: "",
      codeMsg: "",
      validateMsg: "",
      confirmMsg: "",
      userEmail: "",
      password: "",
      confirmPass: "",
      inviteCode: "",
      validateCode: "",
      showPassword: false,
      showConfirmPassword: false,
      checkUserOrNot: false,
      inputPassword: false,
      send_seconds: 0,
      sendTest: this.$t("发送至邮箱"),
      sendEmailTip: "",
      sendEmailTimer: null
    };
  },
  mounted() {
    if (this.$route.query.userEmail) {
      this.userEmail = this.$route.query.userEmail
      this.emailPlaceholder = this.$route.query.emailPlaceholder
      this.inputPassword = true
      this.sendEmail()
    }
  },
  methods: {
    emailBlur() {
      if (this.userEmail == "") {
        this.emailActive = false;
      }
    },
    passBlur() {
      if (this.password == "") {
        this.passActive = false;
      }
    },
    codeBlur() {
      if (this.inviteCode == "") {
        this.codeActive = false;
      }
    },
    validateBlur() {
      if (this.validateCode == "") {
        this.validateActive = false;
      }
    },
    confirmPassBlur() {
      if (this.confirmPass == "") {
        this.confirmActive = false;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    sendEmail() {
      this.emailRet = null
      if (this.emailPlaceholder === 1) {
        let that = this;

        that.send_seconds = 59;
        that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
          "秒后重试"
        )}`;
        that.sendEmailTimer = setInterval(function() {
          if (that.send_seconds > 0) {
            that.send_seconds -= 1;
            that.sendEmailTip = `${that.$t("在")} ${
              that.send_seconds
            } ${that.$t("秒后重试")}`;
          } else {
            clearInterval(that.sendEmailTimer);
          }
        }, 1000);

        sendEmail({
          type: "bind",
          email: this.userEmail
        }).then(res => {
          if (res.data["emailMsg"] !== "") {
            that.emailMsg = res.data["emailMsg"];
            that.send_seconds = 0;
            clearInterval(that.sendEmailTimer);
          }
        });
      }
      if (this.emailPlaceholder === 2) {
        if (!this.userEmail) {
          this.emailRet = 5.5;
          return (this.emailMsg = this.$t("邮箱不能为空"));
        }
        if (!regexNumber.regexEmail(this.userEmail)) {
          this.emailRet = 4;
          return (this.emailMsg = this.$t("请输入有效的号码"));
        }
        this.emailMsg = "";
        let that = this;

        that.send_seconds = 59;
        that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
          "秒后重试"
        )}`;
        that.sendEmailTimer = setInterval(function() {
          if (that.send_seconds > 0) {
            that.send_seconds -= 1;
            that.sendEmailTip = `${that.$t("在")} ${
              that.send_seconds
            } ${that.$t("秒后重试")}`;
          } else {
            clearInterval(that.sendEmailTimer);
          }
        }, 1000);

        sendPhone({
          phone: that.userEmail
        }).then(res => {
          if (res.data["ret"] === -1) {
            that.emailRet = res.data["ret"];
            return (that.emailMsg = this.$t("短信发送失败"));
          }
          if (res.data["ret"] === 1) {
            that.emailRet = res.data["ret"];
            return (that.emailMsg = this.$t("验证码已发送请勿重复获取"));
          }
        });
      }
    },
    next() {
      let that = this;
      this.emailMsg = "";
      this.passMsg = "";

      if (!this.userEmail) {
        this.emailRet = 5.5;
        return (this.emailMsg = this.$t("邮箱不能为空"));
      }
      if (!regexNumber.regexEmail(this.userEmail)) {
        this.emailRet = 3;
        return (this.emailMsg = this.$t("填写格式有误"));
      }
      if (!this.emailPlaceholder) {
        // 注册号格式验证
        checkUser({
          username: this.userEmail
        }).then(res => {
          that.checkUserOrNot = res.data["checkOrNot"];
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) {
            that.emailMsg = that.$t("填写格式有误");
            that.emailRet = 3;
            return;
          }
          if (res.data["regMethod"] === 1) {
            that.emailPlaceholder = res.data["regMethod"];
          }
          if (res.data["regMethod"] === 2) {
            that.emailPlaceholder = res.data["regMethod"];
          }
          if (that.checkUserOrNot) {
            that.emailMsg = that.$t("用户已经存在");
            that.emailRet = 2;
            that.emailActive = true;
            that.emailPlaceholder = null;
          } else {
            that.emailMsg = "";
            that.emailRet = null;
            that.inputPassword = true;
          }
        });
      } else {
        that.emailMsg = "";
        that.passMsg = "";
        that.codeMsg = "";
        that.validateMsg = "";
        that.confirmMsg = "";
        if (!that.validateCode)
          return (that.validateMsg = this.$t("验证码不能为空"));
        if (!regexNumber.regexEmail(this.userEmail)) {
          this.emailRet = 4;
          return (this.emailMsg = this.$t("用户名格式无效"));
        }
        this.emailMsg = "";
        console.log(this.userEmail);
        console.log(this.validateCode);
        bindingEmail({
          email: this.userEmail,
          validateCode: this.validateCode
        }).then(res => {
          if (res.data.status === "success") {
            alert("绑定成功");
            that.$router.go(-1);
          }
          if (res.data.status === "error") {
            alert("绑定失败");
            // that.$router.go(0);
          }
        });
      }
    },
    toLogin() {
      this.$router.replace({
        name: "login"
      });
    }
  },
  watch: {
    emailPlaceholder(newVal) {
      // newVal :1 为电子邮箱； 2 为电话
      console.log(newVal);
      if (newVal === 1) return (this.sendTest = this.$t("发送至邮箱"));
      if (newVal === 2) return (this.sendTest = this.$t("获取验证码"));
    }
  }
};
</script>
<style lang="less" scoped>

.box {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 8% 0;


  .logo {
    width: 100px;
    height: 100px;
    display: block;
    margin: 1% auto 4rem auto;
    border-radius: 12%;
  }

  .welcome {
    font-size: 28px;
  }

  .input-box {
    width: 25%;
    margin: 3rem auto;
    position: relative;
    text-align: left;

    label {
      display: block;
      background-color: white;
      color: #10a37f;
      position: absolute;
      margin-left: 20%;
      margin-top: 1rem;
      padding: 0 .5rem;
      transform: translate(-50%, -100%);
      transition: all .3s ease-in-out;
      transition-delay: .1s;
    }

    label.active {
      top: -2rem;
      left: 2%;
      transform: translate(0, -20%) scale(0.8);
    }

    .pass-label {
      margin-left: 15%;
    }

    .confirm-label {
      margin-left: 24%;
    }

    .verify-label {
      margin-left: 24%;
    }

    input {
      height: 52px;
      width: 100%;
      border: 1px solid #10a37f;
      border-radius: 3px;
      padding: 0 10px;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    input:focus {
      outline: none;
      border: 1px solid #10a37f;
    }

    input::-webkit-input-placeholder {
      margin-left: 10%;
      text-indent: 20px;
    }

    .glyphicon-eye-open,
    .glyphicon-eye-close {
      position: absolute;
      right: 0;
      top: 2rem;
      right: 5%;
    }

    .glyphicon-eye-open:hover,
    .glyphicon-eye-close:hover {
      cursor: pointer;
    }

    .error {
      color: #d00e17;
      font-size: 12px;
      transform: scale(0.8);

      .icon-error {
        vertical-align: middle;
        margin-right: .5rem;
      }
    }

    .forget {
      color: #10a37f;
      margin-top: 1rem;
      display: block;
    }

    .forget:hover {
      cursor: pointer;
    }

    a {
      float: right;
      color: #10a37f;
      text-decoration: underline;
    }

    a:hover {
      cursor: pointer;
    }

    .send {
      background-color: #10a37f;
      color: white;
      outline: none;
      border: none;
      border-radius: 3px;
      position: absolute;
      right: 8px;
      top: 8px;
      height: 36px;
    }

    .email-timer {
      background-color: white;
      color: #10a37f;
      border: 1px solid #10a37f;
      border-radius: 3px;
      position: absolute;
      padding: .5rem;
      right: 12px;
      top: 12px;
      font-size: 12px;
    }
  }

  .continue {
    background-color: #10a37f;
    color: white;
    border: 0px;
    outline: none;
    border-radius: 3px;
    height: 52px;
    width: 25%;
    margin-bottom: 3rem;
  }

  .log-in {
    display: block;
    font-size: 14px;

    span {
      color: #10a37f;
    }

    span:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {

  .input-box,
  .continue {
    width: 80% !important;
  }

  .box {
    .logo {
      margin-top: 5rem !important;
    }
  }
}
</style>
